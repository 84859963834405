import { CoverType, PageFormat } from '@api/gql/graphql'
import { DateTime } from 'luxon'

// Минимальное количество фотографий для создания альбома
export const MIN_PHOTOS_PER_ALBUM = 24

// Максимальное количество фотографий на один альбом
export const MAX_PHOTOS_PER_ALBUM = 1192

const now = DateTime.now()

// Декада - 10 лет
export const DECADE_LIST = [
  now.year,
  now.year - 1,
  now.year - 2,
  now.year - 3,
  now.year - 4,
  now.year - 5,
  now.year - 6,
  now.year - 7,
  now.year - 8,
  now.year - 9,
]

// Полудекада - 5 лет
export const HALF_DECADE_LIST = [
  now.year,
  now.year - 1,
  now.year - 2,
  now.year - 3,
  now.year - 4,
]

export type AlbumPagePadding = {
  left: number
  right: number
  top: number
  bottom: number
}

export const initialAlbumPagePadding: AlbumPagePadding = {
  left: 0, right: 0, top: 0, bottom: 0,
}

export const parsePageFormat = (
  pageFormat: PageFormat,
  padding: AlbumPagePadding = initialAlbumPagePadding,
) => {
  const pixelsInOneMm = 3.7795275591

  const [, sizes] = pageFormat.split('_')
  const [widthCm, heightCm] = sizes.split('x')

  return {
    width: (Math.round(Number(widthCm) * pixelsInOneMm)) - (padding.left + padding.right),
    height: Math.round(Number(heightCm) * pixelsInOneMm) - (padding.top + padding.bottom),
  }
}

export const parseCoverSizes = (coverType: CoverType) => {
  const [, sizes] = coverType.split('_')
  const [rows, cols] = sizes.split('x')

  return {
    rows: Number(rows),
    cols: Number(cols),
    count: Number(rows) * Number(cols),
  }
}

export const defaultCoverType = CoverType.Cover_5x2
export const defaultPageFormat = PageFormat.Format_210x210

export const coverTypes = [
  CoverType.Cover_5x1,
  CoverType.Cover_5x2,
  CoverType.Cover_5x3,
  CoverType.Cover_8x1,
]

// У каждой страницы альбома есть порядковый номер (она начинается с обложки с 0)
// Так как обложка (и intro) по факту не являются страницей
// то отсчет начинается со страницы с фотографиями
// Эта константа отнимает у каждой страницы заданное число
// чтобы нумерация была правильной
export const albumPagingOffset: number = 1

// Все созданные черновики до этой даты будут недоступны для редактирования и оформления
// Это связано с изменениями в макете которые ломают совместимость
// Чтобы клиенты не разочаровались сломанными альбомами мы устанавливаем эту границу
export const getDeprecatedDraftsDate = () => DateTime.fromObject({
  year: 2024,
  month: 11,
  day: 1,
  hour: 0,
  minute: 0,
  second: 0,
}, { zone: 'UTC' })
